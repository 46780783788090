import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface UserInfo {
  id: number;
  customID: string;
  email: string;
  role: string;
  name: string;
  file_path: string;
  company?: string;
}

interface User {
  token: string;
  userInfo: UserInfo;
}

interface UsersState {
  user: User | null;
  userInfo: UserInfo | null;
}

const getUserFromLocalStorage = (): User | null => {
  return JSON.parse(localStorage.getItem("user") || "null");
};

const initialState: UsersState = {
  user: getUserFromLocalStorage(),
  userInfo: getUserFromLocalStorage()?.userInfo || null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    authUser: (state, action: PayloadAction<User>) => {
      const { token, userInfo } = action.payload;
      state.user = { token, userInfo };
      localStorage.setItem("user", JSON.stringify(state.user));
    },

    logoutUser: (state) => {
      state.user = null;
      state.userInfo = null;
      localStorage.removeItem("user");
      toast("You have been logged out");
    },

    getUserInfo: (state) => {
      const user = getUserFromLocalStorage();
      if (user) {
        state.userInfo = user.userInfo;
      }
    },
  },
});

export const { authUser, logoutUser, getUserInfo } = usersSlice.actions;
export default usersSlice.reducer;
