import React, { useEffect } from "react";
import HeaderText from "../components/headerText/HeaderText";
import Desc from "../components/salesrep/Desc";
import Reports from "../components/salesrep/reports/Reports";

const SalesReps: React.FC = () => {

  useEffect(() => {
      // Scroll to the top on the first render
      window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only on the first render
  

  return (
    <section className="p-4 md:p-6 lg:p-8 xl:p-10">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text="Sales Represantative" />
      </section>
      <Desc />
      <Reports />
    </section>
  );
};

export default SalesReps;
