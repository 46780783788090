import React from "react";
import accessURL from "../../../../components/utils/access";
import { useQuery } from "@tanstack/react-query";
import { useDateFilter } from "../../../../contexts/dashboard/DateFilterContext";
import {
  Users,
  UserCheck,
  UserMinus,
  Loader2,
  AlertCircle,
} from "lucide-react";

interface CustomerCategory {
  customerCategory: "A" | "B" | "C";
  categoryCount: number;
}

interface ApiResponse {
  status: string;
  code: number;
  data: CustomerCategory[];
}

const cusCat = async (
  startDate?: string,
  endDate?: string,
  year?: number | null,
  name?: string,
  role?: string
): Promise<ApiResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());
  if (name) params.append("name", name);
  if (role) params.append("_role", role);

  const res = await accessURL(
    `metrics/getCustomersCategoryVisitData?${params.toString()}`
  );
  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data || [],
  };
};

const CategoryCard: React.FC<{
  category: string;
  count: number;
  icon: React.ReactNode;
}> = ({ category, count, icon }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4 transform transition-all hover:scale-105">
    <div className="p-3 rounded-full bg-indigo-100 text-indigo-600">{icon}</div>
    <div>
      <p className="text-sm text-gray-600">Category</p>
      <h3 className="text-2xl font-bold text-gray-900">{category}</h3>
      <p className="text-indigo-600 font-semibold">{count}</p>
    </div>
  </div>
);

// Main component
const CustomerCatReport: React.FC<{ name?: string; role?: string }> = ({
  name = " ",
  role = "",
}) => {
  const dateFilter = useDateFilter();

  console.log(role);

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear;

  const { data, isLoading, error } = useQuery<ApiResponse>({
    queryKey: [
      "getCustomersCategoryVisitData",
      startDate,
      endDate,
      selectedYear,
      name,
      role,
    ],
    queryFn: () => cusCat(startDate, endDate, selectedYear, name, role),
  });

  if (isLoading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  if (error || !data?.data || data.data.length === 0) {
    return (
      <div className="min-h-[400px] flex flex-col items-center justify-center text-center p-6">
        <AlertCircle className="w-12 h-12 text-red-500 mb-4" />
        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          No Customer seen Data Available
        </h2>
      </div>
    );
  }

  const totalCustomers =
    data.data.reduce(
      (acc: number, curr: CustomerCategory) => acc + curr.categoryCount,
      0
    ) || 0;

  return (
    <div className="p-6 space-y-6">
      <div className="flex flex-col space-y-4">
        <h2 className="text-2xl font-bold text-gray-900">Customers Seen</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {data.data.map((category: CustomerCategory) => {
          const icons = {
            A: <Users className="w-6 h-6" />,
            B: <UserCheck className="w-6 h-6" />,
            C: <UserMinus className="w-6 h-6" />,
          };

          return (
            <CategoryCard
              key={category.customerCategory}
              category={category.customerCategory}
              count={category.categoryCount}
              icon={icons[category.customerCategory]}
            />
          );
        })}
      </div>

      <div className="mt-8 bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col space-y-2">
          <h3 className="text-lg font-semibold text-gray-900">Distribution</h3>
          <div className="w-full h-4 bg-gray-200 rounded-full overflow-hidden">
            {data.data.map((category: CustomerCategory, index: number) => {
              const percentage =
                (category.categoryCount / totalCustomers) * 100;
              const colors = [
                "bg-indigo-600",
                "bg-indigo-400",
                "bg-indigo-200",
              ];

              return (
                <div
                  key={category.customerCategory}
                  className={`h-full ${colors[index]} float-left`}
                  style={{ width: `${percentage}%` }}
                />
              );
            })}
          </div>
          <div className="flex justify-center space-x-4 mt-2">
            {data.data.map((category: CustomerCategory, index: number) => {
              const colors = [
                "bg-indigo-600",
                "bg-indigo-400",
                "bg-indigo-200",
              ];
              const percentage = (
                (category.categoryCount / totalCustomers) *
                100
              ).toFixed(1);

              return (
                <div
                  key={category.customerCategory}
                  className="flex items-center space-x-2"
                >
                  <div className={`w-3 h-3 rounded-full ${colors[index]}`} />
                  <span className="text-sm text-gray-600">
                    Category {category.customerCategory}: {percentage}%
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCatReport;
