import React from "react";
import { useState } from "react";
import { Props } from "../../../../../salesRep/components/schedule/types/types";
import DateRangePicker from "../../../dashboard/customs/DateRangePicker";
import Cards from "../../../../../salesRep/components/dashboard/cards/Cards";
import CustomerInfo from "../../../../../salesRep/components/dashboard/customer_info/CustomerInfo";
import Target from "../../../../../salesRep/components/dashboard/target/Target";
import YearSelector from "../../../../../salesRep/components/dashboard/customs/YearSelector";
import VisitAnalytics from "../../../../../salesRep/components/dashboard/visit_analytics/VisitAnalytics";
import CustomerAnalytics from "../../../../../salesRep/components/dashboard/customer_analytics/CustomerAnalytics";
import Reports from "./reports/Reports";
import Latest from "../../../../../salesRep/components/latest/Latest";
import { useParams } from "react-router-dom";
import MostUsed from "../../../../../manager/components/mostUsed/MostUsed";
import UpcomingSchedules from "../../../../../salesRep/components/upcomingSchedules/UpcomingSchedules";

type AdditionalProps = Props & {
  salesRepID?: number;
 
};

const SalesRepMetrics: React.FC<AdditionalProps> = ({
  data,
  isLoading,
  salesRepID,
  
}) => {
  const [activeTab, setActiveTab] = useState<"visit" | "customer">("visit");
  const { rep } = useParams();
  return (
    <div>
      <div className="flex items-center space-x-4 mt-4 py-4 lg:mt-0">
        <DateRangePicker />
        <YearSelector />
      </div>
      <Cards data={data} isLoading={isLoading} />
      <CustomerInfo data={data} isLoading={isLoading} />
      <Target data={data} isLoading={isLoading} />
      <section className="w-full  bg-white rounded-lg shadow-sm">
        <div className="mt-20">
          <div className="flex space-x-4 my-8 px-4">
            <button
              className={`px-6 py-2 rounded-md ${
                activeTab === "visit"
                  ? "bg-black text-white font-bold"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("visit")}
            >
              Visit Analytics
            </button>
            <button
              className={`px-6 py-2 rounded-md ${
                activeTab === "customer"
                  ? "bg-black text-white font-bold"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("customer")}
            >
              Customer Analytics
            </button>
          </div>

          {activeTab === "visit" ? (
            <VisitAnalytics data={data} isLoading={isLoading} />
          ) : (
            <div className="bg-gray-50 rounded-lg border border-gray-100">
              <CustomerAnalytics data={data} isLoading={isLoading} />
            </div>
          )}
          <Reports salesRepID={Number(salesRepID)} />

          <div className="px-4 flex flex-col space-y-8 py-10">
            <Latest name={rep} role="salesRep" />
            <UpcomingSchedules name={rep} role="salesRep" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default SalesRepMetrics;
