import React from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const Large: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);

  return (
    <section className="">
      <div className="flex items-center justify-between px-6 py-0.5">
        {/* <div className="flex items-center border border-gray-300 rounded-md px-6 py-2">
          <input
            type="text"
            placeholder="Search"
            className="outline-none flex-grow"
          />
          <FaSearch className="text-gray-400 text-xl mr-2" />
        </div> */}
        <div></div>

        <div className="flex items-center space-x-8 ml-4">
          <p className="relative px-6 font-bold text-white text-lg before:absolute before:inset-0 before:bg-black before:opacity-90 before:rounded-lg before:shadow-lg before:transition-all hover:before:scale-105 hover:before:opacity-100 flex items-center justify-center overflow-hidden group">
            <span className="relative inline-flex items-center gap-2">
              <span className="relative z-10 group-hover:animate-pulse">
                {userInfo?.name}
              </span>
              <span className="relative z-10 w-2 h-2 rounded-full bg-white/70 group-hover:animate-ping" />
            </span>
          </p>

          <span className="relative bg-gray-300 rounded-full p-2">
            {/* <IoMdNotifications className="text-gray-400 w-8 h-8" /> */}
          </span>

          <div className="flex items-center space-x-2">
            <div>
              <p className="font-boldF">{userInfo?.email}</p>
              <p className="text-sm text-gray-500">{userInfo?.role}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Large;
