import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { useDateFilter } from "../../../../contexts/dashboard/DateFilterContext";

const YearSelector: React.FC = () => {
  const dateFilter = useDateFilter();

  if (!dateFilter) {
    return null;
  }

  const { selectedYear, setSelectedYear } = dateFilter;

  const years = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedYear(value === "" ? null : Number(value));
  };

  return (
    <div className="relative">
      <select
        value={selectedYear?.toString() || ""}
        onChange={handleYearChange}
        className="appearance-none w-full bg-blue-900 text-white px-4 py-2 cursor-pointer rounded-lg pr-8 focus:outline-none focus:ring-2 focus:ring-blue-200"
      >
        <option value="">Select a year</option>
        {years.map((year) => (
          <option key={year} value={year.toString()}>
            {year}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <FaChevronDown />
      </div>
    </div>
  );
};

export default YearSelector;
