import React from "react";
import { Props } from "../../../../salesRep/components/schedule/types/types";

const Cards: React.FC<Props> = ({ data, isLoading }) => {
  const formatNumber = (value: number | null | undefined) => {
    if (value === null || value === undefined || value === 0) return "0";
    return `+${value}`;
  };

  const formatPercentage = (value: number | null | undefined) => {
    if (value === null || value === undefined || value === 0)
      return "0% from last month";
    return `${value > 0 ? "+" : ""}${value}% from last month`;
  };

  const formatP = (value: number | null | undefined) => {
    if (value === null || value === undefined || value === 0) return "0";
    return `${value}% `;
  };

  const formatWeekPercentage = (value: number | null | undefined) => {
    if (value === null || value === undefined || value === 0)
      return "0% from last week";
    return `${value > 0 ? "+" : ""}${value}% from last week`;
  };

  if (isLoading) {
    return (
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className="flex flex-col bg-white rounded-lg shadow-lg border border-gray-200 p-6 space-y-2 animate-pulse"
          >
            <div className="h-6 bg-gray-200 rounded w-3/4"></div>
            <div className="h-8 bg-gray-300 rounded w-1/2"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          </div>
        ))}
      </section>
    );
  }

  return (
    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
      <div className="flex flex-col bg-white rounded-lg shadow-lg border border-gray-200 p-6 space-y-2 hover:shadow-xl transition-shadow duration-200">
        <h5 className="font-semibold text-sm text-gray-800">
          Total Planned Visits
        </h5>
        <p className="text-2xl font-bold text-gray-900">
          {formatNumber(data?.data.totalPlannedVisits?.total_visits)}
        </p>
        <p className="text-sm text-gray-600">
          {formatPercentage(data?.data?.totalPlannedVisits?.percentage_change)}
        </p>
      </div>

      <div className="flex flex-col bg-white rounded-lg shadow-lg border border-gray-200 p-6 space-y-2 hover:shadow-xl transition-shadow duration-200">
        <h5 className="font-semibold text-sm text-gray-800">
          Total Achieved Visits
        </h5>
        <p className="text-2xl font-bold text-gray-900">
          {formatNumber(data?.data?.totalAchievedVisits?.total_reports)}
        </p>
        <p className="text-sm text-gray-600">
          {formatWeekPercentage(
            data?.data?.totalAchievedVisits?.percentage_change
          )}
        </p>
      </div>

      <div className="flex flex-col bg-white rounded-lg shadow-lg border border-gray-200 p-6 space-y-2 hover:shadow-xl transition-shadow duration-200">
        <h5 className="font-semibold text-sm text-gray-800">Call Rate</h5>
        <p className="text-2xl font-bold text-gray-900">
          {formatP(data?.data?.callRate?.call_rate)}
        </p>
      </div>
    </section>
  );
};

export default Cards;
