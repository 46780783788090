import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Det from "../components/reports/single_reports/salesRepDet/Det";
import ManagerMetrics from "../components/reports/ManagerCard/ManagerMetrics";
import { useDateFilter } from "../../contexts/dashboard/DateFilterContext";
import { useQuery } from "@tanstack/react-query";
import accessURL from "../../components/utils/access";
import { ApiResponse } from "../../salesRep/components/schedule/types/types";

const metricsDashboardManager = async (
  startDate?: string,
  endDate?: string,
  year?: number,
  name?: string
): Promise<ApiResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());

  const res = await accessURL(
    `metrics/getMetrics?name=${name}&role=manager&${params.toString()}`
  );
  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data,
  };
};

const SingleReports: React.FC = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on the first render

  const dateFilter = useDateFilter();

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  const { data, isLoading } = useQuery({
    queryKey: ["metrics", startDate, endDate, selectedYear, name],
    queryFn: () =>
      metricsDashboardManager(startDate, endDate, selectedYear, name),
  });

  return (
    <section className="py-10 px-8">
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 mb-6 text-gray-600 hover:text-gray-900 transition-colors duration-200 group"
      >
        <ArrowLeft className="w-5 h-5 transform group-hover:-translate-x-1 transition-transform duration-200" />
        <span className="text-sm font-medium">Back</span>
      </button>
      <Det name={name} role="manager" />
      <ManagerMetrics name={name} data={data} isLoading={isLoading} />
    </section>
  );
};

export default SingleReports;
