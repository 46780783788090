import React from "react";
import Auth from "./Auth";
import { CiViewList } from "react-icons/ci";
import { motion } from "framer-motion";
import {
  containerVariants,
  listItemVariants,
  slideFromTop,
} from "../../animations/Animations";
import { Link, useLocation } from "react-router-dom";

const Login: React.FC = () => {
  const location = useLocation();

  const isSignUp = location.pathname === "/signup";

  const features = [
    "Streamline your visits and maximize your impact.",
    "Create managers to manage your scheduling activities",
    "Create sales rep to enhance your business capabilities",
    "Your gateway to effective scheduling and client management is here.",
    "Efficiently plan your visitation routes and ensure a productive day in the field.",
    "Access your personalized dashboard to schedule appointments, track visits, and improve your engagement with clients.",
  ];

  return (
    <div className="flex items-center justify-center p-4 min-h-screen">
      <div className="max-w-7xl w-full bg-white shadow-md rounded-lg p-8 flex flex-col lg:flex-row">
        <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={slideFromTop}
            className="text-3xl lg:text-4xl font-bold mb-6"
          >
            Welcome to Syncai
          </motion.h1>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                variants={listItemVariants}
                className="flex items-start"
              >
                <CiViewList className="flex-shrink-0 w-6 h-6 mt-1 mr-2 text-blue-500" />
                <p className="text-gray-600">{feature}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
        <div className="lg:w-1/2 lg:pl-8 lg:border-l border-gray-200">
          <h2 className="text-3xl lg:text-4xl font-semibold mb-6">
            {isSignUp ? "Create your account" : "Login to your account"}
          </h2>
          <p className="mb-8 text-gray-700">
            {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
            <Link
              to={isSignUp ? "/login" : "/signup"}
              className="text-blue-500 hover:underline"
            >
              {isSignUp ? "Login" : " Create an account"}
            </Link>
          </p>
          <Auth />
        </div>
      </div>
    </div>
  );
};

export default Login;
