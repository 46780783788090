import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Det from "../components/salesrep/reports/single_reports/salesRepDet/Det";
import RepReport from "../components/salesrep/reports/single_reports/salesRepDet/rep_report/RepReport";
import { ArrowLeft } from "lucide-react";

const SingleReports: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on the first render

  const handleBack = () => {
    navigate(-1);
  };

  const { id } = useParams();
  return (
    <section className="py-10 px-8">
      <div className="flex justify-between items-center p-4 lg:justify-end">
        <button
          onClick={handleBack}
          className="
            group inline-flex items-center gap-2 px-4 py-2 
            text-sm font-medium transition-all duration-200
            bg-blue-900 hover:bg-gray-50 
            text-white hover:text-gray-900
            rounded-lg border border-gray-200 hover:border-gray-300
            shadow-sm hover:shadow
            sm:text-base 
            lg:py-3 lg:px-6 lg:text-lg lg:font-bold
          "
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 transition-transform group-hover:-translate-x-0.5" />
        </button>
      </div>
      <Det id={id ? Number(id) : 0} />
      <RepReport id={id ? Number(id) : 0} />
    </section>
  );
};

export default SingleReports;
