import React, { useState } from "react";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { useQuery } from "@tanstack/react-query";
import accessURL from "../../../components/utils/access";
import { useDateFilter } from "../../../contexts/dashboard/DateFilterContext";
import { LatestData } from "../../../salesRep/components/schedule/types/types";

interface MetaData {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

const Latest: React.FC<{ name?: string; role?: string }> = ({
  name = null,
  role = null,
}) => {
  const [perPage, setPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dateFilter = useDateFilter();
  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear;

  const { data, isLoading } = useQuery({
    queryKey: [
      "latest",
      startDate,
      endDate,
      selectedYear,
      currentPage,
      perPage,
      name,
      role,
    ],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate);
      if (endDate) params.append("end_date", endDate);
      if (name) params.append("name", name);
      if (role) params.append("_role", role);
      if (selectedYear !== null && selectedYear !== undefined) {
        params.append("year", selectedYear.toString());
      }
      params.append("page", currentPage.toString());
      params.append("limit", perPage.toString());

      const response = await accessURL(`latest?${params.toString()}`);
      return response;
    },
  });

  const columns: TableColumn<LatestData>[] = [
    {
      name: "S/N",
      selector: (row) => row.id,
      format: (_, index) => (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "customer",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      width: "150px",
      cell: (row) => {
        let categoryStyle = "";
        let categoryLabel = "";

        switch (row.category) {
          case "A":
            categoryStyle =
              "bg-blue-500 text-white font-semibold px-2 py-1 rounded";
            categoryLabel = "Category A";
            break;
          case "B":
            categoryStyle =
              "bg-green-500 text-white font-semibold px-2 py-1 rounded";
            categoryLabel = "Category B";
            break;
          case "C":
            categoryStyle =
              "bg-red-500 text-white font-semibold px-2 py-1 rounded";
            categoryLabel = "Category C";
            break;
          default:
            categoryStyle = "bg-gray-300 text-black px-2 py-1 rounded";
            categoryLabel = "Unknown";
            break;
        }

        return <span className={categoryStyle}>{categoryLabel}</span>;
      },
    },

    {
      name: "Last Visit",
      selector: (row) => new Date(row.last_visit).toLocaleDateString(),
      sortable: true,
      width: "150px",
    },
    {
      name: "Planned",
      selector: (row) => row.planned,
      sortable: true,
      width: "150px",
    },
    {
      name: "Target",
      selector: (row) => row.target,
      sortable: true,
      width: "150px",
    },

    {
      name: "Achieved",
      selector: (row) => row.achieved,
      sortable: true,
      width: "150px",
    },
    {
      name: "Call Rate",
      selector: (row) => `${row.call_rate}%`,
      sortable: true,
      width: "150px",
    },
    {
      name: "salesRep",
      selector: (row) => row.salesRep,
      sortable: true,
      width: "250px",
    },
  ];

  const customStyles: TableStyles = {
    table: {
      style: {
        backgroundColor: "#f8fafc",
        borderRadius: "0.5rem",
        overflow: "hidden",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#1e40af",
        color: "white",
        fontWeight: "bold",
        textTransform: "uppercase" as const,
        fontSize: "12px",
      },
    },
    rows: {
      style: {
        minHeight: "60px",
      },
      stripedStyle: {
        backgroundColor: "#f1f5f9",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#f8fafc",
        color: "#1e40af",
        fontWeight: "bold",
      },
      pageButtonsStyle: {
        borderRadius: "0.375rem",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0 5px",
        cursor: "pointer",
        transition: "0.2s ease-out",
        color: "#1e40af",
        fill: "#1e40af",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "#cbd5e1",
          fill: "#cbd5e1",
        },
        "&:hover:not(:disabled)": {
          backgroundColor: "#dbeafe",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#bfdbfe",
        },
      },
    },
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <div>
      <h2 className="text-xl font-semibold text-gray-800">Latest</h2>
      <div className="mt-4">
        <DataTable
          columns={columns}
          data={data?.data?.data?.data ?? []}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={data?.data?.meta ?? 0}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          striped
          highlightOnHover
          responsive
          persistTableHead
          customStyles={customStyles}
          noDataComponent={
            <div className="p-4 text-center text-gray-500">
              no latest data available
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Latest;
