import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { fadeInUp } from "../../animations/Animations";
import SignupModal from "../modals/SIgnupModal";
import { BaseURL } from "../../components/utils/url";
import TextInput from "./custom_components/TextInput";
import PasswordInput from "./custom_components/PasswordInput";
import TermsCheckbox from "./custom_components/TermsCheckBox";
import SubmitButton from "./custom_components/SubmitButton";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { authUser } from "../../features/users/UsersSlice";

interface IFormInput {
  fullname?: string;
  email: string;
  companyName?: string;
  password: string;
  termsAccepted?: boolean;
  logo?: File;
}

const Auth: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormInput>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSignUpSuccess, setSignUpSuccess] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [logo, setLogo] = useState<File | null>(null);
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const location = useLocation();
  const isSignUp = location.pathname === "/signup";

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setLogo(file);
      setLogoPreview(URL.createObjectURL(file));
    }
  };

  const removeLogo = () => {
    setLogo(null);
    setLogoPreview(null);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("password", data.password);
    formData.append("email", data.email);
    formData.append("name", data.companyName || "");
    if (logo) {
      formData.append("logo", logo);
    }

    try {
      if (isSignUp) {
        const response = await BaseURL.post("/auth/signup", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          setSignUpSuccess(true);
        } else {
          toast.warn("Organization already exists");
        }
      } else {
        const response = await BaseURL.post("/auth/login", data);
        if (response.status === 200) {
          dispatch(authUser(response?.data?.data));
          const { customID, role } = response?.data?.data?.userInfo;
          setTimeout(() => {
            navigate(`/${role}/${customID}`);
          }, 1000);
        } else {
          toast.error("Invalid Credentials");
        }
      }
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  const passwordValue = watch("password", "");

  const validatePassword = (password: string) => {
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return hasLowercase && hasUppercase && hasNumber && hasSpecialChar;
  };

  const getPasswordBorderColor = () => {
    if (passwordValue) {
      return validatePassword(passwordValue)
        ? "border-green-500"
        : "border-yellow-500";
    } else if (errors.password) {
      return "border-red-500";
    }
    return "border-gray-300";
  };

  return (
    <section>
      <motion.form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4"
        variants={fadeInUp}
        initial="hidden"
        animate="visible"
      >
        {isSignUp && (
          <>
            <TextInput
              label="Company Name"
              id="companyName"
              type="text"
              placeholder="Enter your company name"
              register={register}
              validation={{ required: "Company name is required" }}
              error={errors.companyName}
            />
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Company Logo (Optional)
              </label>
              <div className="mt-1 flex items-center space-x-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
                {logoPreview && (
                  <div className="relative">
                    <img
                      src={logoPreview}
                      alt="Logo preview"
                      className="h-16 w-16 object-contain"
                    />
                    <button
                      type="button"
                      onClick={removeLogo}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 text-xs"
                    >
                      ×
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <TextInput
          label="Email"
          id="email"
          type="email"
          placeholder="Enter your email"
          register={register}
          validation={{
            required: "Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email format",
            },
          }}
          error={errors.email}
        />
        <PasswordInput
          id="password"
          error={errors.password}
          register={register}
          validation={{
            required: "Password is required",
            validate: (value: any) =>
              validatePassword(value) ||
              "Password must include uppercase, lowercase, number, and special character",
          }}
          getPasswordBorderColor={getPasswordBorderColor}
        />
        {!isSignUp && (
          <div className="text-right">
            <Link
              to="/forgot-password"
              className="text-sm text-blue-600 hover:underline"
            >
              Forgot Password?
            </Link>
          </div>
        )}

        {isSignUp && (
          <TermsCheckbox
            error={errors.termsAccepted}
            register={register}
            setTerms={setTerms}
          />
        )}
        <SubmitButton
          isSubmitting={isSubmitting}
          isSignUp={isSignUp}
          terms={terms}
        />
      </motion.form>
      <SignupModal
        show={isSignUpSuccess}
        onClose={() => setSignUpSuccess(false)}
      />
    </section>
  );
};

export default Auth;
