import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import accessURL from "../../../../../../components/utils/access";
import Sort from "../../../sort/Sort";
import Action from "./Action";
import { SalesRepData } from "../../../../../../salesRep/components/schedule/types/types";
import Update from "../manager/update/Update";
import Reassign from "./Reassign";
import FilterSection from "./FilterSection";

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

interface Filters {
  startDate?: string;
  endDate?: string;
  date?: boolean;
  search?: string;
  name?: boolean;
  assigned?: boolean;
}

const SalesRep: React.FC = () => {
  const [data, setData] = useState<SalesRepData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    fetchSalesReps(currentPage, perPage);
  }, [currentPage, perPage, filters]);

  const fetchSalesReps = async (page: number, pageSize: number) => {
    setLoading(true);

    const params: Record<string, string> = {};

    // Only add defined and non-empty string values to params
    if (filters.startDate) params.start_date = filters.startDate;
    if (filters.endDate) params.end_date = filters.endDate;
    if (filters.search) params.name = filters.search;
    if (filters.date !== undefined) params.date = String(filters.date);
    if (filters.name !== undefined) params.contentName = String(filters.name);
    if (filters.assigned !== undefined)
      params.assigned = String(filters.assigned);

    const queryParams = new URLSearchParams(params);

    try {
      const response = await accessURL.get<{
        data: { salesRep: SalesRepData[]; pagination: PaginationInfo };
      }>(
        `salesRep?page=${page}&per_page=${pageSize}${
          queryParams.toString() ? `&${queryParams.toString()}` : ""
        }`
      );
      setData(response.data.data.salesRep);
      setTotalRows(response.data.data.pagination.total);
    } catch (error) {
      console.error("Error fetching sales reps:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns: TableColumn<SalesRepData>[] = [
    {
      name: "S/N",
      selector: (row: SalesRepData) => row.ID,
      format: (row: SalesRepData, index: number) =>
        (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row: SalesRepData) => row.name,
      width: "300px",
    },
    {
      name: "Email",
      selector: (row: SalesRepData) => row.email,
      width: "300px",
    },
    {
      name: "Assigned Manager",
      selector: (row: SalesRepData) => row.assignedManager || "N/A",
    },
    {
      name: "Actions",
      cell: (row: SalesRepData) => (
        <Action id={row.ID} name={row.name} assigned={row.assigned} />
      ),
      width: "300px",
    },
    {
      name: "",
      cell: (row) => <Update row={row} mode="salesrep" />,
      width: "250px",
    },
    {
      name: "",
      cell: (row) => <Reassign row={row} />,
      width: "250px",
    },
    {
      name: "Date Created",
      selector: (row) => {
        const date = new Date(row.created_at);
        return new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(date);
      },
      wrap: true,
      width: "200px",
    },
  ];

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="py-6">
        <FilterSection onFilterChange={handleFilterChange} />
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          striped
          highlightOnHover
          responsive
        />
      </div>
    </div>
  );
};

export default SalesRep;
