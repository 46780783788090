import React from "react";
import { Props } from "../../../../salesRep/components/schedule/types/types";
import { motion } from "framer-motion";
import { FiUsers } from "react-icons/fi";
import { HiOutlineUserGroup } from "react-icons/hi";

const CustomersDetails: React.FC<Props> = ({ data, isLoading }) => {
  const customerInfo = data?.data?.customerInfo;

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    show: { y: 0, opacity: 1 },
  };

  if (isLoading) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const totalCustomers = customerInfo?.total_customers ?? 0;

  const categoryColors: Record<"A" | "B" | "C", string> = {
    A: "bg-emerald-500",
    B: "bg-blue-500",
    C: "bg-purple-500",
  };

  return (
    <motion.div
      initial="hidden"
      animate="show"
      variants={container}
      className="w-full p-4 space-y-6"
    >
      {/* Total Customers Card */}
      <motion.div
        variants={item}
        className="bg-white rounded-xl shadow-lg p-6 border border-gray-100"
      >
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-gray-500 text-sm font-medium">
              Total Customers
            </h2>
            <p className="text-3xl font-bold text-gray-800 mt-1">
              {customerInfo?.total_customers ?? 0}
            </p>
          </div>
          <div className="bg-blue-100 p-3 rounded-full">
            <FiUsers className="w-6 h-6 text-blue-500" />
          </div>
        </div>
      </motion.div>

      {/* Category Breakdown */}
      <motion.div
        variants={item}
        className="bg-white rounded-xl shadow-lg p-6 border border-gray-100"
      >
        <h2 className="text-gray-700 font-semibold mb-4 flex items-center gap-2">
          <HiOutlineUserGroup className="w-5 h-5" />
          Customer Categories
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {Object.entries(customerInfo?.category_breakdown ?? {}).map(
            ([category, count]) => (
              <motion.div
                key={category}
                variants={item}
                className="relative overflow-hidden rounded-lg p-4 border border-gray-100"
              >
                <div className="relative z-10">
                  <h3 className="text-2xl font-bold text-gray-800 mt-1">
                    {count}
                  </h3>
                  <div
                    className={`inline-block px-2 py-1 rounded-full text-xs text-white mt-2 ${
                      categoryColors[category as keyof typeof categoryColors]
                    }`}
                  >
                    Category {category}
                  </div>
                </div>
                <div
                  className={`absolute top-0 right-0 w-24 h-24 -mr-8 -mt-8 rounded-full opacity-10 ${
                    categoryColors[category as keyof typeof categoryColors]
                  }`}
                ></div>
              </motion.div>
            )
          )}
        </div>

        {/* Category Distribution Bar */}
        {totalCustomers > 0 && (
          <div className="mt-6">
            <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden flex">
              {Object.entries(customerInfo?.category_breakdown ?? {}).map(
                ([category, count]) => {
                  const percentage =
                    (count / (customerInfo?.total_customers ?? 1)) * 100;
                  return (
                    <motion.div
                      key={category}
                      initial={{ width: 0 }}
                      animate={{ width: `${percentage}%` }}
                      transition={{ duration: 0.8, ease: "easeOut" }}
                      className={`h-full ${
                        categoryColors[category as keyof typeof categoryColors]
                      }`}
                    />
                  );
                }
              )}
            </div>
            <div className="flex justify-between mt-2 text-xs text-gray-500">
              {Object.entries(customerInfo?.category_breakdown ?? {}).map(
                ([category, count]) => {
                  const totalCustomers = customerInfo?.total_customers ?? 0;
                  const percentage =
                    totalCustomers > 0
                      ? ((count / totalCustomers) * 100).toFixed(1)
                      : "0.0";

                  return <span key={category}>{percentage}%</span>;
                }
              )}
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default CustomersDetails;
