import React, { useEffect } from "react";
import Det from "../components/reports/single_reports/salesRepDet/Det";
import { useNavigate, useParams } from "react-router-dom";
import AllRep from "../components/allReports/AllRep";
import { useQuery } from "@tanstack/react-query";
import accessURL from "../../components/utils/access";
import { ArrowLeft } from "lucide-react";

const AllReports: React.FC = () => {
  const { salesRepID } = useParams();

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: ["getRepName"],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (salesRepID) params.append("id", salesRepID);

      const response = await accessURL(`repName?${params.toString()}`);
      return response;
    },
  });

  return (
    <section>
      <div className="flex justify-between items-center p-4 lg:justify-end">
        <button
          onClick={handleBack}
          className="
            group inline-flex items-center gap-2 px-4 py-2 
            text-sm font-medium transition-all duration-200
            bg-blue-900 hover:bg-gray-50 
            text-white hover:text-gray-900
            rounded-lg border border-gray-200 hover:border-gray-300
            shadow-sm hover:shadow
            sm:text-base 
            lg:py-3 lg:px-6 lg:text-lg lg:font-bold
          "
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 transition-transform group-hover:-translate-x-0.5" />
        </button>
      </div>
      <Det name={data?.data?.name} />
      <AllRep id={Number(salesRepID)} />
    </section>
  );
};

export default AllReports;
