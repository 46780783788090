import React, { useEffect, useState } from "react";
import HeaderText from "../components/headerText/HeaderText";
import Cards from "../components/dashboard/cards/Cards";
import VisitAnalytics from "../components/dashboard/visit_analytics/VisitAnalytics";
import accessURL from "../../components/utils/access";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "../../salesRep/components/schedule/types/types";
import DateRangePicker from "../components/dashboard/customs/DateRangePicker";
import { useDateFilter } from "../../contexts/dashboard/DateFilterContext";
import Target from "../components/dashboard/target/Target";
import YearSelector from "../components/dashboard/customs/YearSelector";
import CustomerAnalytics from "../components/dashboard/customer_analytics/CustomerAnalytics";
import CustomerInfo from "../components/dashboard/customer_info/CustomerInfo";
import TopManagers from "../../manager/components/topManagers/TopManagers";
import Latest from "../components/latest/Latest";
import MostUsed from "../components/mostUsed/MostUsed";

const dashboard = async (
  startDate?: string,
  endDate?: string,
  year?: number
): Promise<ApiResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());

  const res = await accessURL(`dashboard?${params.toString()}`);
  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data,
  };
};

const Dashboard: React.FC = () => {
  const dateFilter = useDateFilter();

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  const { data, isLoading } = useQuery({
    queryKey: ["adminDashboardDetails", startDate, endDate, selectedYear],
    queryFn: () => dashboard(startDate, endDate, selectedYear),
  });

  const [activeTab, setActiveTab] = useState<"visit" | "customer">("visit");

  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on the first render

  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text="dashboard" />
      </section>
      <main>
        <div className="flex items-center space-x-4 mt-4 py-4 lg:mt-0">
          <DateRangePicker />

          <YearSelector />
        </div>
        <Cards data={data} isLoading={isLoading} />
        <Target data={data} isLoading={isLoading} />
        <CustomerInfo data={data} isLoading={isLoading} />

        <div className="flex flex-col lg:flex-row w-full gap-4 p-4">
          <section className="w-full flex flex-col space-y-8 lg:w-[60%] bg-white rounded-lg shadow-sm p-6">
            <Latest />
            <MostUsed />
          </section>

          <section className="w-full lg:w-[40%] bg-white rounded-lg shadow-sm">
            <div className="mt-20">
              <div className="flex space-x-4 my-8 px-4">
                <button
                  className={`px-6 py-2 rounded-md ${
                    activeTab === "visit"
                      ? "bg-black text-white font-bold"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setActiveTab("visit")}
                >
                  Visit Analytics
                </button>
                <button
                  className={`px-6 py-2 rounded-md ${
                    activeTab === "customer"
                      ? "bg-black text-white font-bold"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setActiveTab("customer")}
                >
                  Customer Analytics
                </button>
              </div>

              {activeTab === "visit" ? (
                <VisitAnalytics data={data} isLoading={isLoading} />
              ) : (
                <div className="bg-gray-50 rounded-lg border border-gray-100">
                  <CustomerAnalytics data={data} isLoading={isLoading} />
                </div>
              )}

              <TopManagers />
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
