import React, { createContext, useState, useContext, ReactNode } from "react";

// Modified interface to allow null for selectedYear
interface DateFilterContextType {
  startDate: string;
  endDate: string;
  selectedYear: number | null; // Changed to allow null
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  setSelectedYear: (year: number | null) => void; // Updated to handle null
}

// Create the context with a default undefined value
const DateFilterContext = createContext<DateFilterContextType | undefined>(
  undefined
);

// Provider component with modified initial state
export const DateFilterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<number | null>(null); // Initialize as null

  return (
    <DateFilterContext.Provider
      value={{
        startDate,
        endDate,
        selectedYear,
        setStartDate,
        setEndDate,
        setSelectedYear,
      }}
    >
      {children}
    </DateFilterContext.Provider>
  );
};

// Custom hook remains the same
export const useDateFilter = () => {
  const context = useContext(DateFilterContext);
  return context;
};
