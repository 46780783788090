import React, { useEffect, useState } from "react";
import HeaderText from "../components/headerText/HeaderText";
import CreateSchedule from "../components/schedule/CreateSchedule";
import Slots from "../components/schedule/slots/Slots";

const Schedule: React.FC = () => {
  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on the first render

  const [anotherDateState, setAnotherDateState] = useState<Date | null>(null);

  const handleGetSelectedDate = (date: Date) => {
    setAnotherDateState(date);
  };

  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center py-3">
        <HeaderText text="Schedule" />
        <div className="flex flex-col-reverse space-y-10 space-x-0 md:flex-col lg:space-y-0 lg:space-x-10 lg:flex-row">
          <CreateSchedule anotherDateState={anotherDateState} />
        </div>
      </section>

      <section>
        <Slots get={handleGetSelectedDate} />
      </section>
    </div>
  );
};

export default Schedule;
