import React, { useState, FormEvent, useEffect } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import FileInput from "./FileInput";
import {
  modalVariants,
  overlayVariants,
} from "../../../../../../animations/Animations";
import { FaSpinner } from "react-icons/fa";

interface ContentUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (formData: FormData) => void;
  isLoading: boolean;
}

const ContentUploadModal: React.FC<ContentUploadModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  isLoading,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    setIsFormValid(
      !!file &&
        name.trim() !== "" &&
        description.trim() !== "" &&
        purpose.trim() !== ""
    );
  }, [file, name, description, purpose]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!isFormValid) {
      if (!file) toast.error("Please select a file.");
      if (name.trim() === "") toast.error("Please enter a name.");
      if (description.trim() === "") toast.error("Please enter a description.");
      if (purpose.trim() === "") toast.error("Please enter a purpose.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file as File);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("purpose", purpose);

    onUpload(formData);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 py-10 z-50"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={overlayVariants}
    >
      <motion.div
        className="bg-white p-6 rounded-lg w-full max-w-2xl relative z-60"
        variants={modalVariants}
      >
        <h2 className="text-3xl font-bold mb-4 py-3">Upload Content</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <FileInput onChange={setFile} />
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full px-4 rounded-md border py-2 border-gray-900 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
              className="mt-1 block  py-2 border px-4 border-gray-900 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="purpose"
              className="block text-sm font-medium text-gray-700"
            >
              Purpose
            </label>
            <input
              id="purpose"
              type="text"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              className="mt-1  py-2 border border-gray-900 px-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div className="flex justify-center space-x-2 py-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 w-full border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isFormValid}
              className={`px-4 py-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                isFormValid
                  ? "bg-blue-900 hover:bg-blue-700"
                  : "bg-gray-400 cursor-not-allowed"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                </>
              ) : (
                " Upload"
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default ContentUploadModal;
