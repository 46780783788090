import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  modalVariants,
  overlayVariants,
} from "../../../../animations/Animations";
import ContentSelector from "../custom/ContentSelector";
import ProductSelector from "../custom/ProductSelector";
import CustomerSelector from "../custom/CustomerSelector";
import { CustomerData } from "../../schedule/types/types";
import { FaSpinner } from "react-icons/fa";

interface FormData {
  objective: string;
  dateOfVisit: string;
  content: number[];
  products: number[];
  comment: string;
  customer: CustomerData | null;
}

interface FormErrors {
  objective?: string;
  dateOfVisit?: string;
  content?: string;
  products?: string;
  comment?: string;
  customer?: string;
}

interface ReportModalProps {
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
  isLoading: boolean;
}

const ReportModal: React.FC<ReportModalProps> = ({
  onClose,
  onSubmit,
  isLoading,
}) => {
  const [formData, setFormData] = useState<FormData>({
    objective: "",
    dateOfVisit: "",
    content: [],
    products: [],
    comment: "",
    customer: null,
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    let isValid = true;

    if (!formData.objective.trim()) {
      newErrors.objective = "Objective is required";
      isValid = false;
    }

    if (!formData.dateOfVisit) {
      newErrors.dateOfVisit = "Date of visit is required";
      isValid = false;
    }

    if (formData.content.length === 0) {
      newErrors.content = "At least one content must be selected";
      isValid = false;
    }

    if (formData.products.length === 0) {
      newErrors.products = "At least one product must be selected";
      isValid = false;
    }

    if (!formData.comment.trim()) {
      newErrors.comment = "Comment is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: undefined });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-50 flex items-start justify-center pt-8 pb-8"
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50"
          onClick={onClose}
        />
        <motion.div
          className="bg-white w-full max-w-lg mx-4 rounded-lg shadow-lg relative z-10 flex flex-col max-h-[90vh]"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="flex-shrink-0 sticky top-0 bg-white p-4 border-b border-gray-200 rounded-t-lg">
            <button
              className="absolute right-4 top-4 text-gray-500 text-2xl text-red-500"
              onClick={onClose}
            >
              &times;
            </button>
            <h2 className="text-xl font-bold">Report</h2>
          </div>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col flex-1 overflow-hidden"
          >
            <div className="p-4 flex-1 overflow-y-auto">
              <div className="space-y-4">
                <div>
                  <label className="block text-gray-700">Objective</label>
                  <input
                    type="text"
                    className={`w-full p-2 border rounded-md ${
                      errors.objective ? "border-red-500" : "border-gray-300"
                    }`}
                    placeholder="Enter objective"
                    name="objective"
                    value={formData.objective}
                    onChange={handleInputChange}
                  />
                  {errors.objective && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.objective}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-700">Date of Visit</label>
                  <input
                    type="date"
                    className={`w-full p-2 border rounded-md ${
                      errors.dateOfVisit ? "border-red-500" : "border-gray-300"
                    }`}
                    name="dateOfVisit"
                    value={formData.dateOfVisit}
                    onChange={handleInputChange}
                  />
                  {errors.dateOfVisit && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.dateOfVisit}
                    </p>
                  )}
                </div>

                <CustomerSelector
                  selectedCustomer={formData.customer}
                  onCustomerSelect={(customer) => {
                    setFormData({ ...formData, customer });
                    setErrors({ ...errors, customer: undefined });
                  }}
                  error={errors.customer}
                />

                <ContentSelector
                  selectedContents={formData.content}
                  onContentsChange={(contents) => {
                    setFormData({ ...formData, content: contents });
                    setErrors({ ...errors, content: undefined });
                  }}
                  error={errors.content}
                />

                <ProductSelector
                  selectedProducts={formData.products}
                  onProductsChange={(products) => {
                    setFormData({ ...formData, products: products });
                    setErrors({ ...errors, products: undefined });
                  }}
                  error={errors.products}
                />

                <div>
                  <label className="block text-gray-700">Comment</label>
                  <textarea
                    className={`w-full p-2 border rounded-md ${
                      errors.comment ? "border-red-500" : "border-gray-300"
                    }`}
                    rows={4}
                    placeholder="Enter comment"
                    name="comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                  />
                  {errors.comment && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.comment}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-shrink-0 sticky bottom-0 bg-white p-4 border-t border-gray-200 rounded-b-lg">
              <button
                type="submit"
                className="w-full bg-blue-900 text-white py-2 rounded-md flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <FaSpinner className="animate-spin" />
                ) : (
                  "Create Report"
                )}
              </button>
            </div>
          </form>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ReportModal;
