import React, { useState, useEffect } from "react";
import { FaPlus, FaSpinner } from "react-icons/fa";
import Modal from "./Modal";
import accessURL from "../../../../../../components/utils/access";
import { toast } from "react-toastify";

interface FormData {
  productName: string;
}

const AddProduct: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    productName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const { productName } = formData; // Removed plan from validation
    setIsFormValid(productName.trim() !== "");
  }, [formData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isFormValid) return;

    setIsLoading(true);

    const response = await accessURL.post("/products/create", {
      name: formData.productName.trim(),
      plan: "0", // Always send 0 for planned visits
    });

    setIsLoading(false);

    if (response.status === 201) {
      toast.success("Product created successfully!");
      setIsModalOpen(false);
      setFormData({ productName: "" });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else if (response.status === 409) {
      toast.error("A product with this name already exists.");
    } else {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({ productName: "" });
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center gap-2 bg-blue-900 text-white px-4 py-2 rounded text-xl"
      >
        <FaPlus /> Add Product
      </button>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2 className="text-2xl font-bold mb-4 py-4">Add Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="productName"
              className="block mb-2 font-semi-bold text-lg"
            >
              Product name
            </label>
            <input
              type="text"
              id="productName"
              value={formData.productName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>

          <div className="flex justify-center gap-2">
            <button
              type="button"
              onClick={handleCloseModal}
              className="px-4 py-2 border border-blue-900 rounded w-full hover:bg-blue-900 hover:text-white hover:ease-in hover:transition-all"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 border bg-blue-900 text-white rounded w-full hover:bg-blue-800 hover:ease-in hover:transition-all flex items-center justify-center ${
                !isFormValid && "opacity-50 cursor-not-allowed"
              }`}
              disabled={isLoading || !isFormValid}
            >
              {isLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                </>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddProduct;
