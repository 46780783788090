import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { X } from "lucide-react";
import { format } from "date-fns";
import { useDateFilter } from "../../../contexts/dashboard/DateFilterContext";
import accessURL from "../../../components/utils/access";
import ScheduleDetailsModal from "../schedule/slots/schedules/ScheduleDetailsModal";
import { Schedule } from "../schedule/types/types";

interface Meta {
  total: number;
  page: string;
  limit: string;
  totalPages: number;
  filters: {
    dateRange: {
      start: string | null;
      end: string | null;
    };
    year: number | null;
  };
}

interface ApiResponse {
  data: {
    status: string;
    code: number;
    data: {
      data: Schedule[];
      meta: Meta;
    };
  };
  status: number;
  statusText: string;
}

// Constants
const timeColors = [
  { bg: "bg-purple-100", text: "text-purple-800" },
  { bg: "bg-blue-100", text: "text-blue-800" },
  { bg: "bg-green-100", text: "text-green-800" },
  { bg: "bg-rose-100", text: "text-rose-800" },
  { bg: "bg-amber-100", text: "text-amber-800" },
  { bg: "bg-teal-100", text: "text-teal-800" },
];

const UpcomingSchedules: React.FC<{ name?: string; role?: string }> = ({
  name = null,
  role = null,
}) => {
  const [perPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState<Schedule[]>([]);
  const dateFilter = useDateFilter();
  const listRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const formatTime = (time: string): string => {
    return format(new Date(`1970-01-01T${time}`), "hh:mm a");
  };

  const { data: response, isLoading } = useQuery<ApiResponse>({
    queryKey: [
      "latest",
      dateFilter?.startDate,
      dateFilter?.endDate,
      dateFilter?.selectedYear,
      currentPage,
      name,
      role,
    ],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (dateFilter?.startDate)
        params.append("start_date", dateFilter.startDate);
      if (dateFilter?.endDate) params.append("end_date", dateFilter.endDate);
      if (dateFilter?.selectedYear != null) {
        params.append("year", dateFilter.selectedYear.toString());
      }
      if (name) params.append("name", name);
      if (role) params.append("_role", role);
      params.append("page", currentPage.toString());
      params.append("limit", perPage.toString());

      return await accessURL(`upcomingSchedule?${params.toString()}`);
    },
  });

  // Handle data updates using useEffect instead of onSuccess
  useEffect(() => {
    if (response) {
      const newSchedules = response.data.data.data;
      const totalPages = response.data.data.meta.totalPages;

      setScheduleData((prevSchedules) => {
        if (currentPage === 1) {
          return newSchedules;
        }
        // Filter out any duplicates based on id
        const existingIds = new Set(prevSchedules.map((s) => s.id));
        const uniqueNewSchedules = newSchedules.filter(
          (s) => !existingIds.has(s.id)
        );
        return [...prevSchedules, ...uniqueNewSchedules];
      });

      setHasMore(currentPage < totalPages);
      setIsLoadingMore(false);
    }
  }, [response, currentPage]);

  const handleScroll = () => {
    if (!listRef.current || isLoadingMore || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const scrollThreshold = 50; // pixels from bottom

    if (scrollHeight - (scrollTop + clientHeight) < scrollThreshold) {
      setIsLoadingMore(true);
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleScheduleClick = (schedule: Schedule): void => {
    setSelectedSchedule(schedule);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Reset everything when date filter changes
    setCurrentPage(1);
    setScheduleData([]);
    setHasMore(true);
    setIsLoadingMore(false);
  }, [dateFilter?.startDate, dateFilter?.endDate, dateFilter?.selectedYear]);

  if (isLoading && currentPage === 1) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col h-full">
      <h2 className="text-lg font-bold mb-4">Upcoming Schedule</h2>
      <div className="bg-white rounded-lg shadow flex-1 flex flex-col">
        {scheduleData.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            No schedules available
          </div>
        ) : (
          <div
            ref={listRef}
            onScroll={handleScroll}
            className="overflow-y-auto"
            style={{
              height: "280px",
              scrollbarWidth: "thin",
              scrollbarColor: "#E5E7EB transparent",
            }}
          >
            <ul className="divide-y divide-gray-200">
              {scheduleData.map((schedule, index) => {
                const colorStyle = timeColors[index % timeColors.length];
                return (
                  <li
                    key={`${schedule.id}-${index}`}
                    onClick={() => handleScheduleClick(schedule)}
                    className="p-4 hover:bg-gray-50 transition-colors duration-200 cursor-pointer"
                  >
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                      <div className="flex-1 min-w-0">
                        <h3 className="font-bold text-gray-900 truncate">
                          {schedule.customer}
                        </h3>
                        <p className="text-sm text-gray-600 truncate">
                          {schedule.objective.length > 50
                            ? `${schedule.objective.substring(0, 50)}...`
                            : schedule.objective}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span
                          className={`text-sm font-medium ${colorStyle.bg} ${colorStyle.text} py-1 px-3 rounded-full`}
                        >
                          {formatTime(schedule.startTime)} -{" "}
                          {formatTime(schedule.endTime)}
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            {isLoadingMore && (
              <div className="p-4 flex justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
              </div>
            )}
          </div>
        )}
      </div>

      <ScheduleDetailsModal
        schedule={selectedSchedule}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default UpcomingSchedules;
