import { useQuery } from "@tanstack/react-query";
import React from "react";
import { FaUser } from "react-icons/fa";
import accessURL from "../../../../../components/utils/access";

interface DetProps {
  name?: string;
  role?: string;
}

const Det: React.FC<DetProps> = ({ name, role = "" }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["repManager"],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (name) params.append("rep", name);

      const response = await accessURL(`repManager?${params.toString()}`);
      return response;
    },
  });

  // Helper function to check if manager name is valid
  const getManagerName = () => {
    if (isLoading) return "Loading...";
    if (!data?.data?.name || data.data.name === "") return "Not Assigned";
    return data.data.name;
  };

  return (
    <div className="w-full p-6 md:p-8 space-y-6">
      {/* Sales Representative Card */}
      <div className="relative overflow-hidden bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-lg transition-transform duration-300 hover:scale-102">
        <div className="absolute top-0 left-0 w-full h-1.5 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500" />
        <div className="absolute -right-12 -top-12 w-32 h-32 bg-blue-100 rounded-full opacity-40 blur-lg" />
        <div className="absolute -left-12 -bottom-12 w-40 h-40 bg-indigo-100 rounded-full opacity-40 blur-lg" />

        <div className="relative px-6 py-8 md:px-8 md:py-10">
          <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8">
            <div className="relative group">
              <div className="absolute inset-0 bg-blue-200 rounded-full blur-xl opacity-50 transform scale-110 group-hover:scale-125 transition-transform duration-300" />
              <div className="relative bg-gradient-to-br from-blue-500 via-indigo-500 to-purple-500 p-4 rounded-full transform transition-transform duration-300 hover:rotate-6">
                <FaUser className="w-8 h-8 md:w-10 md:h-10 text-white" />
              </div>
            </div>

            <div className="text-center md:text-left space-y-2">
              <div className="relative">
                <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600">
                  {name || "Loading..."}
                </h2>
                <div className="mt-2 h-1 w-12 md:w-16 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 rounded-full mx-auto md:mx-0" />
              </div>

              <div className="relative mt-2">
                <span className="inline-block px-4 py-1.5 text-sm md:text-base font-medium text-indigo-600 bg-indigo-50 rounded-full border border-indigo-100 shadow-sm">
                  {!role ? "Sales Representative" : "Manager"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Manager Card - Only shown when no role is provided */}
      {!role && (
        <div className="relative overflow-hidden bg-gradient-to-br from-gray-900 to-black rounded-2xl shadow-lg transition-transform duration-300 hover:scale-102">
          <div className="absolute top-0 left-0 w-full h-1.5 bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800" />
          <div className="absolute -right-12 -top-12 w-32 h-32 bg-gray-800 rounded-full opacity-40 blur-lg" />
          <div className="absolute -left-12 -bottom-12 w-40 h-40 bg-gray-800 rounded-full opacity-40 blur-lg" />

          <div className="relative px-6 py-8 md:px-8 md:py-10">
            <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8">
              <div className="relative group">
                <div className="absolute inset-0 bg-gray-700 rounded-full blur-xl opacity-50 transform scale-110 group-hover:scale-125 transition-transform duration-300" />
                <div className="relative bg-gradient-to-br from-gray-700 to-gray-800 p-4 rounded-full transform transition-transform duration-300 hover:rotate-6">
                  <FaUser className="w-8 h-8 md:w-10 md:h-10 text-white" />
                </div>
              </div>

              <div className="text-center md:text-left space-y-2">
                <div className="relative">
                  <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-white">
                    {getManagerName()}
                  </h2>
                  <div className="mt-2 h-1 w-12 md:w-16 bg-gradient-to-r from-gray-600 to-gray-400 rounded-full mx-auto md:mx-0" />
                </div>

                <div className="relative mt-2">
                  <span className="inline-block px-4 py-1.5 text-sm md:text-base font-medium text-white bg-gray-800 rounded-full border border-gray-700 shadow-sm">
                    Manager
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Det;
