"use client";

import React from "react";
import { UserCircle } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface ManagerProps {
  manager?: string;
}

const ManagerCard: React.FC<ManagerProps> = ({ manager }) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.customID || "";

  const handleViewReport = () => {
    navigate(`/admin/${email}/report/${manager}`);
  };
  if (!manager) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full p-6 bg-white rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
    >
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <motion.div whileHover={{ scale: 1.05 }} className="relative">
            <div className="absolute inset-0 bg-blue-200 rounded-full blur-md opacity-50" />
            <div className="relative bg-gradient-to-br from-blue-100 to-blue-200 p-3 rounded-full shadow-inner">
              <UserCircle className="w-10 h-10 text-blue-600" />
            </div>
          </motion.div>

          <div className="flex flex-col space-y-4">
            <h2 className="text-2xl font-bold text-gray-800 capitalize">
              {manager}
            </h2>
            <p className="text-slate-500 capitalize">manager</p>
          </div>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleViewReport}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
        >
          View Report
        </motion.button>
      </div>
    </motion.div>
  );
};

export default ManagerCard;
