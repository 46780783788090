import React, { useEffect, useState } from "react";
import HeaderText from "../components/headerText/HeaderText";
import Tabs from "../components/products/tabs/Tab";
import AddProduct from "../components/products/display/products/addProducts/AddProduct";
import AddContent from "../components/products/display/contents/addContents.tsx/AddContent";
import { useLocation } from "react-router-dom";

const Product: React.FC = () => {
  const [role, setRole] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on the first render

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleParam = searchParams.get("role");
    setRole(roleParam || "");
  }, [location]);

  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text={role === "contents" ? "Contents" : "Products"} />
        {role === "contents" ? <AddContent /> : <AddProduct />}
      </section>

      <Tabs />
    </div>
  );
};

export default Product;
