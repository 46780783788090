import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  AiOutlineAlert,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import accessURL from "../../../../../components/utils/access";
import { Schedule } from "../../types/types";

const Cancel: React.FC<{ schedule: Schedule }> = ({ schedule }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleDelete = async () => {
    await accessURL.post(`/schedules/delete?id=${schedule.id}`, {
      customer: schedule.customer,
    });
    setIsSuccess(true);
    setShowNotification(true);
    setShowConfirmation(false);

    setTimeout(() => {
      setShowNotification(false);

      window.location.reload();
    }, 2000);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowConfirmation(true)}
        className="py-2 px-6 bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors duration-200"
        type="button"
      >
        Cancel Schedule
      </button>

      <AnimatePresence>
        {showConfirmation && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
              <div className="flex items-center mb-4">
                <AiOutlineAlert className="w-6 h-6 text-red-500 mr-2" />
                <h2 className="text-lg font-semibold">Confirm Cancellation</h2>
              </div>
              <p className="mb-6">
                Are you sure you want to cancel this schedule? This action
                cannot be undone.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowConfirmation(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                >
                  Keep Schedule
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md transition-colors duration-200"
                >
                  Yes, Cancel Schedule
                </button>
              </div>
            </div>
          </motion.div>
        )}

        {showNotification && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className={`fixed top-4 right-4 p-4 rounded-lg shadow-lg flex items-center ${
              isSuccess ? "bg-green-500" : "bg-red-500"
            } text-white z-50`}
          >
            {isSuccess ? (
              <>
                <AiOutlineCheckCircle className="w-6 h-6 mr-2" />
                Schedule has been cancelled successfully
              </>
            ) : (
              <>
                <AiOutlineCloseCircle className="w-6 h-6 mr-2" />
                Failed to cancel schedule. Please try again.
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Cancel;
