import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoClose } from "react-icons/io5";
import { Props } from "../../../../salesRep/components/schedule/types/types";
import { toast } from "react-toastify";
import accessURL from "../../../../components/utils/access";
import { TargetIcon } from "lucide-react";

const defaultTargetValues = {
  A: 0,
  B: 0,
  C: 0,
};

const categoryColors = {
  A: "text-red-600",
  B: "text-yellow-600",
  C: "text-green-600",
};

const SetTarget: React.FC<Props> = ({ data, isLoading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [targetValues, setTargetValues] = useState<
    Record<string, string | number>
  >({});
  const [isSaving, setIsSaving] = useState(false);

  const currentTargets = data?.data?.targets || defaultTargetValues;
  const totalTarget = data?.data?.totalTargets?.total_target || 0;
  const categoryBreakdown =
    data?.data?.totalTargets?.category_breakdown || defaultTargetValues;

  const handleInputChange = (category: "A" | "B" | "C", value: string) => {
    setTargetValues((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handleSubmit = async () => {
    // Convert empty strings to 0 and validate all inputs
    const processedValues = Object.entries(targetValues).reduce(
      (acc, [key, value]) => {
        const numValue = value === "" ? 0 : parseInt(value as string) || 0;
        acc[key] = numValue;
        return acc;
      },
      {} as Record<string, number>
    );

    // Check if any non-zero values are required
    const hasAllZeros = Object.values(processedValues).every(
      (value) => value === 0
    );
    if (hasAllZeros) {
      toast.error(
        "At least one category must have a target value greater than 0"
      );
      return;
    }

    setIsSaving(true);
    try {
      const response = await accessURL.post(
        "targets/setTargets",
        processedValues
      );

      if (response.status === 200) {
        toast.success("Targets updated successfully");
        setIsModalOpen(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data?.message || "Failed to update targets");
      }
    } catch (error) {
      toast.error("An error occurred while updating targets");
    } finally {
      setIsSaving(false);
    }
  };

  const openModal = () => {
    // Convert current targets to strings for the input fields
    const initialValues = Object.entries(currentTargets).reduce(
      (acc, [key, value]) => {
        acc[key] = value.toString();
        return acc;
      },
      {} as Record<string, string>
    );

    setTargetValues(initialValues);
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <motion.div
        className="flex items-center justify-center h-32"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="text-gray-600">Loading...</div>
      </motion.div>
    );
  }

  return (
    <>
      <motion.div
        className="p-4 sm:p-6 bg-white rounded-lg shadow-lg space-y-6"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      >
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <motion.h2
            className="text-xl sm:text-2xl font-bold text-gray-800"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            Targets
          </motion.h2>
          <button
            onClick={openModal}
            className="w-full sm:w-auto px-4 py-2 bg-blue-900 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Update Targets
          </button>
        </div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: {
              opacity: 1,
              scale: 1,
              transition: {
                delayChildren: 0.2,
                staggerChildren: 0.1,
              },
            },
          }}
        >
          {(Object.entries(currentTargets) as ["A" | "B" | "C", number][]).map(
            ([category, value]) => (
              <motion.div
                key={category}
                className="p-4 bg-gray-50 rounded-lg shadow-md border border-gray-200"
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
                }}
                whileTap={{ scale: 0.98 }}
                variants={{
                  hidden: { opacity: 0, y: 10 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <p
                  className={`text-sm font-medium ${categoryColors[category]}`}
                >
                  Category {category}
                </p>
                <p
                  className={`text-xl font-semibold ${categoryColors[category]}`}
                >
                  {value}
                </p>
              </motion.div>
            )
          )}
        </motion.div>

        <motion.div
          className="mt-6 p-4 bg-gray-50 rounded-lg shadow-md border border-gray-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-3">
              <TargetIcon className="text-blue-600 text-2xl" />
              <div>
                <p className="text-xl font-medium text-black">
                  Customers Target{" "}
                </p>
                <p className="text-xl font-semibold text-blue-600 md:text-2xl">
                  {totalTarget.toLocaleString()}
                </p>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 mt-4">
              {(
                Object.entries(categoryBreakdown) as ["A" | "B" | "C", number][]
              ).map(([category, value]) => (
                <div
                  key={category}
                  className={`p-3 rounded-lg ${categoryColors[category]} bg-opacity-10`}
                >
                  <p className="text-sm font-medium">Target {category}</p>
                  <p className="text-lg font-bold">{value.toLocaleString()}</p>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </motion.div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white rounded-lg p-4 sm:p-6 w-full max-w-md relative"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <IoClose size={24} />
              </button>

              <h3 className="text-xl font-bold mb-4">Set Target Values</h3>

              <div className="space-y-4">
                {(
                  Object.entries(currentTargets) as ["A" | "B" | "C", number][]
                ).map(([category]) => (
                  <div key={category} className="space-y-2">
                    <label
                      className={`block text-sm font-medium ${categoryColors[category]}`}
                    >
                      Category {category}
                    </label>
                    <input
                      type="text"
                      value={targetValues[category] ?? ""}
                      onChange={(e) =>
                        handleInputChange(category, e.target.value)
                      }
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter target value"
                    />
                  </div>
                ))}
              </div>

              <div className="mt-6 flex flex-col sm:flex-row justify-end space-y-3 sm:space-y-0 sm:space-x-3">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 w-full sm:w-auto"
                  disabled={isSaving}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={isSaving}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto flex items-center justify-center"
                >
                  {isSaving ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Saving...
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default SetTarget;
