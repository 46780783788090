import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useDateFilter } from "../../../contexts/dashboard/DateFilterContext";
import accessURL from "../../../components/utils/access";
import { useNavigate } from "react-router-dom";

interface SalesRep {
  id: number;
  name: string;
  call_rate: number;
  total_visits: number;
  total_targets: number;
}

interface Meta {
  total: number;
  page: string;
  limit: string;
  totalPages: number;
  filters?: Record<string, unknown>;
}

interface ApiResponse {
  data: {
    status: string;
    code: number;
    data: {
      data: SalesRep[];
      meta: Meta;
    };
  };
}

const TopSalesReps: React.FC<{
  name?: string;
  role?: string;
  from?: string;
}> = ({ name = null, role = null, from = null }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [salesReps, setSalesReps] = useState<SalesRep[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [perPage] = useState<number>(10);

  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.customID || "";

  const dateFilter = useDateFilter();
  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear;

  const listRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { data: apiResponse, isLoading } = useQuery<
    unknown,
    Error,
    ApiResponse
  >({
    queryKey: [
      "topSalesReps",
      startDate,
      endDate,
      selectedYear,
      currentPage,
      name,
      role,
    ],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate);
      if (endDate) params.append("end_date", endDate);
      if (name) params.append("name", name);
      if (role) params.append("_role", role);
      if (selectedYear != null) {
        params.append("year", selectedYear.toString());
      }
      params.append("page", currentPage.toString());
      params.append("limit", perPage.toString());

      const response = await accessURL(`topSalesRep?${params.toString()}`);
      await new Promise((resolve) => setTimeout(resolve, 800));
      return response;
    },
  });

  useEffect(() => {
    if (apiResponse) {
      const newReps = apiResponse?.data?.data.data;
      const meta = apiResponse?.data?.data.meta;

      if (meta) {
        setSalesReps((prevReps) => {
          if (currentPage === 1) {
            return newReps;
          }
          const existingIds = new Set(prevReps.map((rep) => rep.id));
          const uniqueNewReps = newReps.filter(
            (rep) => !existingIds.has(rep.id)
          );
          return [...prevReps, ...uniqueNewReps];
        });

        setHasMore(currentPage < meta.totalPages);
      }
      setIsLoadingMore(false);
    }
  }, [apiResponse, currentPage]);

  const handleScroll = () => {
    if (!listRef.current || isLoadingMore || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const scrollThreshold = 50;

    if (scrollHeight - (scrollTop + clientHeight) < scrollThreshold) {
      setIsLoadingMore(true);
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleRepClick = (rep: SalesRep) => {
    const baseRoute =
      from === "_admin" ? `/admin/${email}` : `/manager/${email}`;
    navigate(`${baseRoute}/report/${encodeURIComponent(rep.name)}/${rep.id}`);
  };

  useEffect(() => {
    setCurrentPage(1);
    setSalesReps([]);
    setHasMore(true);
    setIsLoadingMore(false);
  }, [startDate, endDate, selectedYear]);

  if (isLoading && currentPage === 1) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col h-full">
      <h2 className="text-lg font-bold mb-4">Top Sales Reps</h2>
      <div className="bg-white rounded-lg shadow flex-1 flex flex-col">
        {salesReps.length === 0 ? (
          <div className="p-4 text-center text-gray-500">
            No sales reps found
          </div>
        ) : (
          <div
            ref={listRef}
            onScroll={handleScroll}
            className="overflow-y-auto"
            style={{
              height: "200px",
              scrollbarWidth: "thin",
              scrollbarColor: "#E5E7EB transparent",
            }}
          >
            <ul className="divide-y divide-gray-200">
              {salesReps.map((rep) => (
                <li
                  key={rep.id}
                  onClick={() => handleRepClick(rep)}
                  className="p-4 hover:bg-gray-50 transition-colors duration-200 cursor-pointer"
                >
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0 mr-4">
                      <span className="font-medium text-sm text-gray-900 truncate block">
                        {rep.name}
                      </span>
                    </div>
                    <div className="flex-shrink-0 flex flex-col items-end">
                      <span className="text-xs text-gray-500">Call Rate</span>
                      <span className="text-sm text-gray-600">
                        {rep.call_rate.toFixed(2)}%
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            {isLoadingMore && (
              <div className="p-4 flex justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopSalesReps;
