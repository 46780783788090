import React from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "../../../assets/Logo.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface MobileHeaderProps {
  toggleMobileNav: () => void;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleMobileNav }) => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  return (
    <header className="lg:hidden bg-white shadow-md p-4 fixed top-0 left-0 w-full flex justify-between items-center z-50">
      <button
        className="text-3xl text-gray-800"
        onClick={toggleMobileNav}
        aria-label="Toggle mobile navigation"
      >
        <RxHamburgerMenu />
      </button>
      <img
        src={userInfo?.file_path || Logo}
        alt="syncai logo"
        className="h-8 w-auto"
      />
    </header>
  );
};

export default MobileHeader;
