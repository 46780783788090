// Customer.tsx
import React, { useEffect, useState } from "react";
import HeaderText from "../components/headerText/HeaderText";
import Customers from "../components/customers/Customers";
import AddCustomers from "../components/customers/addCustomers/AddCustomers";

const Customer: React.FC = () => {
  useEffect(() => {
    // Scroll to the top on the first render
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only on the first render

  return (
    <div className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center py-3">
        <HeaderText text="customers" />
        <div className="flex flex-col-reverse space-y-10 space-x-0 md:flex-col lg:space-y-0 lg:space-x-10 lg:flex-row">
          <AddCustomers />
        </div>
      </section>

      <section>
        <Customers />
      </section>
    </div>
  );
};

export default Customer;
