import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import accessURL from "../../../../../../components/utils/access";
import Sort from "../../../sort/Sort";
import Action from "./Action";
import Update from "./update/Update";
import FilterSection from "./FilterSection";

interface ManagerData {
  ID: number;
  name: string;
  role: string;
  email: string;
  companyID: string;
  cpa: number | null;
  salesAchieved: number | null;
  cpaGrowthPeriod: number | null;
  salesGrowthPeriod: number | null;
  created_at: string;
}

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

interface Filters {
  startDate?: string;
  endDate?: string;
  date?: boolean;
  search?: string;
  name?: boolean;
}

const Manager: React.FC = () => {
  const [data, setData] = useState<ManagerData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  useEffect(() => {
    fetchManagers(currentPage, perPage);
  }, [currentPage, perPage, filters]);

  const fetchManagers = async (page: number, pageSize: number) => {
    setLoading(true);

    const params: Record<string, string> = {};

    // Only add defined and non-empty string values to params
    if (filters.startDate) params.start_date = filters.startDate;
    if (filters.endDate) params.end_date = filters.endDate;
    if (filters.search) params.name = filters.search;
    if (filters.date !== undefined) params.date = String(filters.date);
    if (filters.name !== undefined) params.contentName = String(filters.name);

    const queryParams = new URLSearchParams(params);

    try {
      const response = await accessURL.get<{
        data: { managers: ManagerData[]; pagination: PaginationInfo };
      }>(
        `managers?page=${page}&per_page=${pageSize}${
          queryParams.toString() ? `&${queryParams.toString()}` : ""
        }`
      );
      setData(response.data.data.managers);
      setTotalRows(response.data.data.pagination.total);
    } catch (error) {
      console.error("Error fetching managers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns: TableColumn<ManagerData>[] = [
    {
      name: "S/N",
      selector: (row: ManagerData) => row.ID,
      format: (row: ManagerData, index: number) =>
        (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row: ManagerData) => row.name,
      width: "300px",
    },
    {
      name: "EMAIL",
      selector: (row: ManagerData) => row.email,
      width: "350px",
    },
    // {
    //   name: "CPA(%)",
    //   selector: (row: ManagerData) => (row.cpa !== null ? row.cpa : 0),
    // },
    // {
    //   name: "Sales Achieved(%)",
    //   selector: (row: ManagerData) =>
    //     row.salesAchieved !== null ? row.salesAchieved : 0,
    // },
    // {
    //   name: "CPA Growth Vs Period",
    //   selector: (row: ManagerData) =>
    //     row.cpaGrowthPeriod !== null ? row.cpaGrowthPeriod : 0,
    //   width: "180px",
    // },
    // {
    //   name: "Sales Growth Vs Period",
    //   selector: (row: ManagerData) =>
    //     row.salesGrowthPeriod !== null ? row.salesGrowthPeriod : 0,
    //   width: "200px",
    // },

    {
      name: "Actions",
      cell: (row: ManagerData) => <Action id={row.ID} name={row.name} />,
      width: "250px",
    },
    {
      name: "",
      cell: (row) => <Update row={row} mode="manager" />,
      width: "250px",
    },
    {
      name: "Date Created",
      selector: (row) => {
        const date = new Date(row.created_at);
        return new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(date);
      },
      wrap: true,
      width: "200px",
    },
  ];

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="py-6">
        <FilterSection onFilterChange={handleFilterChange} />
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <DataTable
          columns={columns}
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          striped
          highlightOnHover
          responsive
        />
      </div>
    </div>
  );
};

export default Manager;
