import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import Man from "../components/reports/allSalesReps/Man";
import All from "../components/reports/allSalesReps/All";

const AllSalesReps: React.FC = () => {
  const { manager } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="flex justify-end mb-6">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 px-4 py-3 my-8 text-gray-700 bg-gray-100 rounded-lg shadow-md hover:bg-gray-200 transition-colors duration-200"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="text-sm font-medium">Back</span>
        </button>
      </div>

      <section className="py-6 md:py-12">
        <Man manager={manager} />
        <All manager={manager} />
      </section>
    </div>
  );
};

export default AllSalesReps;
