import React from "react";
import { User, Mail, Building, UserCog, BadgeCheck } from "lucide-react";
import { motion } from "framer-motion";
import { Profile } from "../schedule/types/types";

interface DetailsProps {
  data: Profile;
}

const Details: React.FC<DetailsProps> = ({ data }) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.div
      className="p-4 sm:p-6 md:p-8 max-w-6xl mx-auto"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="relative">
          <div className="h-32 bg-gradient-to-r from-blue-600 to-blue-400" />

          {/* Profile Section */}
          <div className="relative px-6 sm:px-8 -mt-16">
            <motion.div
              className="flex flex-col sm:flex-row items-center gap-6"
              variants={itemVariants}
            >
              {/* Avatar */}
              <div className="relative">
                <div className="w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-white dark:bg-gray-700 border-4 border-white dark:border-gray-700 shadow-lg flex items-center justify-center">
                  <User className="w-12 h-12 sm:w-16 sm:h-16 text-blue-600" />
                </div>
              </div>

              {/* Name and Title */}
              <div className="text-center sm:text-left">
                <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 dark:text-white">
                  {data.name}
                </h1>
              </div>
            </motion.div>
          </div>

          {/* Details Grid */}
          <div className="mt-8 px-6 sm:px-8 pb-8">
            <div className="h-px w-full bg-gray-200 dark:bg-gray-700 mb-8" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Email */}
              <motion.div
                className="group hover:bg-gray-50 dark:hover:bg-gray-800/50 p-4 rounded-lg transition-colors"
                variants={itemVariants}
              >
                <div className="flex items-start gap-4">
                  <div className="rounded-full bg-blue-100 dark:bg-blue-900/30 p-3 group-hover:bg-blue-600 group-hover:text-white transition-colors">
                    <Mail className="w-6 h-6" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Email Address
                    </p>
                    <p className="text-base sm:text-lg font-medium text-gray-900 dark:text-white mt-1 break-words">
                      {data.email}
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Company */}
              <motion.div
                className="group hover:bg-gray-50 dark:hover:bg-gray-800/50 p-4 rounded-lg transition-colors"
                variants={itemVariants}
              >
                <div className="flex items-start gap-4">
                  <div className="rounded-full bg-blue-100 dark:bg-blue-900/30 p-3 group-hover:bg-blue-600 group-hover:text-white transition-colors">
                    <Building className="w-6 h-6" />
                  </div>
                  <div className="min-w-0 flex-1">
                    {/* <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                      Company ID
                    </p> */}
                    <p className="text-base sm:text-lg font-medium text-gray-900 dark:text-white mt-1 break-words">
                      {data?.companyID || data?.customID}
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Manager - Only show if assigned */}
              {data.assignedManager && (
                <motion.div
                  className="group hover:bg-gray-50 dark:hover:bg-gray-800/50 p-4 rounded-lg transition-colors"
                  variants={itemVariants}
                >
                  <div className="flex items-start gap-4">
                    <div className="rounded-full bg-blue-100 dark:bg-blue-900/30 p-3 group-hover:bg-blue-600 group-hover:text-white transition-colors">
                      <UserCog className="w-6 h-6" />
                    </div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                        Assigned Manager
                      </p>
                      <p className="text-base sm:text-lg font-medium text-gray-900 dark:text-white mt-1 break-words">
                        {data.assignedManager}
                      </p>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Details;
