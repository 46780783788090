import RepReport from "../../admin/components/reports/single_reports/salesRepDet/rep_report/RepReport";
import Profile from "../../salesRep/pages/Profile";
import ManagerLayout from "../layout/ManagerLayout";
import Dashboard from "../pages/Dashboard";
import Product from "../pages/Product";
import SalesReps from "../pages/SalesReps";
import SingleReports from "../pages/SingleReports";

const ManagerRoutes = [
  {
    path: "/manager/:name",
    element: <ManagerLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "salesrep",
        element: <SalesReps />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "report/:rep/:salesRepID",
        element: <RepReport />,
      },
      {
        path: "report/:id",
        element: <SingleReports />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
    ],
  },
];
export default ManagerRoutes;
