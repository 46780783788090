import React, { useState } from "react";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { useQuery } from "@tanstack/react-query";
import { useDateFilter } from "../../../contexts/dashboard/DateFilterContext";
import accessURL from "../../../components/utils/access";

interface MostUsedData {
  customer: string;
  product: string;
  most_used_content: string;
  use_count: number;
  last_used: string;
  salesRep: string;
  manager: string;
}

const MostUsed: React.FC = () => {
  const [perPage, setPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dateFilter = useDateFilter();
  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear;

  const { data, isLoading } = useQuery({
    queryKey: [
      "mostUsed",
      startDate,
      endDate,
      selectedYear,
      currentPage,
      perPage,
    ],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate);
      if (endDate) params.append("end_date", endDate);
      if (selectedYear !== null && selectedYear !== undefined) {
        params.append("year", selectedYear.toString());
      }
      params.append("page", currentPage.toString());
      params.append("limit", perPage.toString());

      const response = await accessURL(`mostUsed?${params.toString()}`);
      return response;
    },
  });

  const columns: TableColumn<MostUsedData>[] = [
    {
      name: "S/N",
      cell: (_, index) => (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: " salesRep",
      selector: (row) => row.salesRep,
      sortable: true,
      width: "250px",
      wrap: true,
    },
    {
      name: "Manager",
      selector: (row) => row.manager,
      sortable: true,
      width: "250px",
      wrap: true,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      width: "180px",
      wrap: true,
    },
    {
      name: "Content Used",
      selector: (row) => row.most_used_content,
      sortable: true,
      wrap: true,
      width: "300px",
      cell: (row) => (
        <div className="text-sm line-clamp-2" title={row.most_used_content}>
          {row.most_used_content}
        </div>
      ),
    },
    {
      name: "Usage Count",
      selector: (row) => row.use_count,
      sortable: true,
      width: "140px",
      cell: (row) => (
        <div className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
          {row.use_count}x
        </div>
      ),
    },
    {
      name: "Last Used",
      selector: (row) => row.last_used,
      sortable: true,
      width: "180px",
      cell: (row) => (
        <div className="text-sm">
          {new Date(row.last_used).toLocaleDateString()}
          <br />
          <span className="text-gray-500">
            {new Date(row.last_used).toLocaleTimeString()}
          </span>
        </div>
      ),
    },
  ];

  const customStyles: TableStyles = {
    table: {
      style: {
        backgroundColor: "#f8fafc",
        borderRadius: "0.5rem",
        overflow: "hidden",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#1e40af",
        color: "white",
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: "12px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
        "&:nth-of-type(odd)": {
          backgroundColor: "#f1f5f9",
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#f8fafc",
        color: "#1e40af",
        fontWeight: "bold",
      },
      pageButtonsStyle: {
        borderRadius: "0.375rem",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0 5px",
        cursor: "pointer",
        transition: "0.2s ease-out",
        color: "#1e40af",
        fill: "#1e40af",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "#cbd5e1",
          fill: "#cbd5e1",
        },
        "&:hover:not(:disabled)": {
          backgroundColor: "#dbeafe",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#bfdbfe",
        },
      },
    },
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <div className="w-full">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Most Used Contents
      </h2>
      <div className="overflow-hidden rounded-lg shadow-sm">
        <DataTable
          columns={columns}
          data={data?.data?.data.data ?? []}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={data?.data?.data.meta.total ?? 0}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          responsive
          persistTableHead
          customStyles={customStyles}
          noDataComponent={
            <div className="p-8 text-center text-gray-500">
              No usage data available
            </div>
          }
        />
      </div>
    </div>
  );
};

export default MostUsed;
