import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import * as pdfjs from "pdfjs-dist";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";

interface PDFViewerProps {
  url: string;
  onClose: () => void;
}

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

const PDFViewer: React.FC<PDFViewerProps> = ({ url, onClose }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      if (!containerRef.current) return;

      try {
        // Load PDF document
        const loadingTask = pdfjs.getDocument(url);
        const pdf: PDFDocumentProxy = await loadingTask.promise;
        const pageNumber = 1;
        const page = await pdf.getPage(pageNumber);

        // Calculate scale to fit the container
        const viewport = page.getViewport({ scale: 1.0 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        if (!context) {
          throw new Error("Canvas context not available");
        }

        // Clear previous content
        while (containerRef.current.firstChild) {
          containerRef.current.removeChild(containerRef.current.firstChild);
        }

        // Set canvas dimensions
        const containerWidth = containerRef.current.clientWidth;
        const scale = Math.min(containerWidth / viewport.width, 1.5);
        const scaledViewport = page.getViewport({ scale });

        canvas.height = scaledViewport.height;
        canvas.width = scaledViewport.width;

        containerRef.current.appendChild(canvas);

        const renderContext = {
          canvasContext: context,
          viewport: scaledViewport,
        };

        await page.render(renderContext).promise;
        setIsLoading(false);
      } catch (error) {
        tryIframeLoad();
      }
    };

    const tryIframeLoad = () => {
      if (!containerRef.current) return;

      // Clear previous content
      while (containerRef.current.firstChild) {
        containerRef.current.removeChild(containerRef.current.firstChild);
      }

      const iframe = document.createElement("iframe");
      iframe.src = url;
      iframe.style.width = "100%";
      iframe.style.height = "80vh";
      iframe.style.border = "none";

      iframe.onload = () => {
        setIsLoading(false);
        setError(null);
      };

      iframe.onerror = () => {
        setError("Failed to load Document. Please try downloading instead.");
        setIsLoading(false);
        setShowFallback(true);
      };

      containerRef.current.appendChild(iframe);
    };

    loadPdf();
  }, [url]);

  const handleDownload = () => {
    window.open(url, "_blank");
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]"
      style={{ zIndex: 10000 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-white p-6 rounded-lg shadow-xl relative max-w-4xl w-full mx-4 z-[60]"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handleDownload}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Download PDF
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            Close
          </button>
        </div>

        {isLoading && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}

        {error && !showFallback && (
          <div className="text-center p-4">
            <p className="text-red-500 mb-2">{error}</p>
          </div>
        )}

        {showFallback && (
          <div className="text-center py-12 px-4 bg-gray-50 rounded-lg">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V7a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Unable to preview PDF
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              We couldn't load the preview, but you can still view the document
            </p>
            <div className="mt-6">
              <button
                onClick={handleDownload}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Download PDF
              </button>
            </div>
          </div>
        )}

        <div
          ref={containerRef}
          className="overflow-auto max-h-[80vh]"
          style={{ display: isLoading || showFallback ? "none" : "block" }}
        />
      </motion.div>
    </motion.div>
  );
};

export default PDFViewer;
