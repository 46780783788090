import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Loader, AlertCircle, UserCircle } from "lucide-react";
import { motion } from "framer-motion";
import Details from "../components/profile/Details";
import accessURL from "../../components/utils/access";
import { ProfileResponse } from "../components/schedule/types/types";

const Profile: React.FC = () => {
  const { data, isLoading, isError } = useQuery<ProfileResponse>({
    queryKey: ["profile"],
    queryFn: async () => {
      const response = await accessURL("profile");
      return response.data;
    },
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader className="animate-spin text-primary w-10 h-10" />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-red-600">
        <AlertCircle className="w-12 h-12" />
        <p className="mt-4">
          Failed to load profile data. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <section className="p-4 lg:p-8 bg-gray-100/10 shadow-xl min-h-screen">
      <motion.div
        className="flex items-center gap-3 mb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.6,
          ease: "easeOut",
        }}
      >
        <UserCircle className="w-8 h-8 lg:w-10 lg:h-10 text-blue-600" />
        <h1 className="text-2xl lg:text-3xl py-8 font-bold text-gray-800">
          Profile Details
        </h1>
      </motion.div>
      <Details data={data.data[0]} />
    </section>
  );
};

export default Profile;
