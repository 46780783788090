import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaUser, FaTimes } from "react-icons/fa";
import CustomersDetails from "./CustomersDetails";
import { Props } from "../../../../salesRep/components/schedule/types/types";
import CustomerCatReport from "./CustomerCatReport";

const CustomerInfo: React.FC<Props> = ({ data, isLoading }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleCustomerInfo = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="flex flex-col items-start p-4">
      {/* Toggle Button */}
      <button
        onClick={toggleCustomerInfo}
        className="bg-blue-900 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg flex items-center space-x-2 transition-colors duration-300"
      >
        {isVisible ? (
          <>
            <FaTimes className="mr-2" /> Close Customers Info
          </>
        ) : (
          <>
            <FaUser className="mr-2" /> Show Customers Info
          </>
        )}
      </button>

      {/* Animated Customer Info Section */}
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-100%", opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.3,
            }}
            className="w-full"
          >
            <CustomersDetails data={data} isLoading={isLoading}/>
            <CustomerCatReport/>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CustomerInfo;
