import React from "react";
import Manager from "./manager/Manager";
import SalesRep from "./salesRep/SalesRep";
import { useNavigate } from "react-router-dom";
import { FaUserPlus, FaUserTie } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface PersonnelConProps {
  activeTab: string;
}

const PersonnelContent: React.FC<PersonnelConProps> = ({ activeTab }) => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);

  const userEmail = userInfo?.customID;
  const navigate = useNavigate();

  return (
    <div className="w-full max-w-screen-4xl shadow-sm bg-white rounded-xl">
      <div className="flex justify-start p-4">
        {activeTab === "manager" && (
          <button
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white shadow-md rounded-md transition-all duration-300 hover:bg-blue-700"
            onClick={() => navigate(`/admin/${userEmail}/create-manager`)}
          >
            <FaUserTie className="text-lg" />
            <span>Create Manager</span>
          </button>
        )}
        {activeTab === "sales_rep" && (
          <button
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white shadow-md rounded-md transition-all duration-300 hover:bg-blue-700"
            onClick={() => navigate(`/admin/${userEmail}/create-salesRep`)}
          >
            <FaUserPlus className="text-lg" />
            <span>Create Sales Rep</span>
          </button>
        )}
      </div>

      <div className="p-4">
        {activeTab === "manager" && (
          <div className="rounded-lg">
            <Manager />
          </div>
        )}
        {activeTab === "sales_rep" && (
          <div className="border rounded-lg">
            <SalesRep />
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonnelContent;
