import React, { useState } from "react";
import { Reports } from "../../schedule/types/types";
import { LuBook } from "react-icons/lu";
import { HiDotsVertical } from "react-icons/hi";
import Modal from "./Modal";
import accessURL from "../../../../components/utils/access";
import FollowupModal from "../../../../admin/components/reports/single_reports/salesRepDet/rep_report/Followup";
import { toast } from "react-toastify";

interface ReportCardProps {
  report: Reports;
  access?: string;
}

const ReportCard: React.FC<ReportCardProps> = ({ report, access = "rep" }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [followUpPlan, setFollowUpPlan] = useState("");
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFollowUpSuccess, setIsFollowUpSuccess] = useState(false);

  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const categoryColor = () => {
    switch (report.customerCategory) {
      case "A":
        return "text-green-700";
      case "B":
        return "text-yellow-700";
      case "C":
        return "text-red-700";
      default:
        return "text-gray-700";
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDelete = async () => {
    const response = await accessURL.post(`/reports/delete?id=${report.id}`);
    if (response.status === 200) {
      setDeleteSuccess(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setDeleteSuccess(false);
      closeDeleteConfirm();
    }
  };

  const handleAddFollowUp = () => {
    setIsFollowUpModalOpen(true);
  };

  const handleUpdateModal = () => {
    setIsUpdateModal(true);
  };

  const handleSubmitFollowUp = async (plan: string) => {
    try {
      setLoading(true);
      const response = await accessURL.post(
        `reports/followUpPlan?id=${report.id}`,
        {
          followUpPlan: plan,
        }
      );

      if (response.status === 200) {
        toast.success("Follow-up plan updated successfully!");
        setFollowUpPlan(plan);
        setIsFollowUpSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        console.error("Error saving follow-up plan:", response.status);
        toast.error("Error saving follow-up plan. Please try again later.");
      }
    } catch (error) {
      console.error("Error saving follow-up plan:", error);
      toast.error("Error saving follow-up plan. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseFollowUpModal = () => {
    setIsFollowUpModalOpen(false);
    setIsFollowUpSuccess(false);
  };

  return (
    <div className="bg-white shadow-xl rounded-md p-4">
      {/* Top Section */}
      <div className="flex justify-between items-center mb-2 py-2">
        <LuBook
          className="text-xl cursor-pointer text-blue-600 md:text-4xl"
          onClick={openModal}
        />
        {access === "rep" && (
          <div className="relative">
            <HiDotsVertical
              className="cursor-pointer text-xl text-blue-600 md:text-4xl hover:text-blue-700 transition-colors"
              onClick={toggleOptions}
            />

            {showOptions && (
              <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg overflow-hidden z-50">
                <div className="flex flex-col">
                  <button
                    onClick={openDeleteConfirm}
                    className="px-4 py-3 text-left text-red-500 hover:bg-red-50 transition-colors w-full border-b border-gray-100 font-medium"
                  >
                    Delete
                  </button>
                  <button
                    onClick={handleAddFollowUp}
                    className="px-4 py-1 text-left text-blue-900 hover:bg-blue-50 transition-colors w-full font-medium flex items-center gap-2"
                  >
                    Add Follow-Up
                  </button>
                  <button
                    onClick={handleUpdateModal}
                    className="px-4 py-1 text-left text-green-900 hover:bg-blue-50 transition-colors w-full font-medium flex items-center gap-2"
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <h3 className="text-xl capitalize font-bold text-black mb-2 truncate py-3">
        {report.customer}
      </h3>
      <div className="flex flex-col md:flex-row items-center justify-between z-0">
        {report.followUpPlan && report.followUpPlan !== "" && (
          <div
            className="group relative max-w-[70%]"
            title="Click on view to see follow up plan"
          >
            <p className="text-purple-600 font-medium truncate">
              Follow up: {report.followUpPlan}
            </p>
          </div>
        )}
        <p className={`text-right text-xl md:text-2xl ${categoryColor()}`}>
          {report.customerCategory}
        </p>
      </div>

      <p className="text-gray-600 mb-2 truncate py-2">
        Details of Visit: {report.objective}
      </p>
      <button
        className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center transition duration-300 ease-in-out w-full"
        onClick={openModal}
      >
        View
      </button>

      {/* View Report Modal */}
      <Modal isOpen={modalOpen} onClose={closeModal} report={report} />

      {/* Delete Confirmation Modal */}
      {deleteConfirmOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg text-center w-80">
            <h2 className="text-lg font-bold mb-4">Confirm Delete</h2>
            <p className="text-gray-700 mb-4">
              Are you sure you want to delete this report?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDelete}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                Yes, Delete
              </button>
              <button
                onClick={closeDeleteConfirm}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Success Modal */}
      {deleteSuccess && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 animate-fadeIn">
          <div className="bg-white p-6 rounded shadow-lg text-center w-80">
            <h2 className="text-lg font-bold mb-4 text-green-600">
              Deleted Successfully
            </h2>
            <p className="text-gray-700">The report has been deleted.</p>
          </div>
        </div>
      )}

      <FollowupModal
        isOpen={isFollowUpModalOpen}
        onClose={handleCloseFollowUpModal}
        onSubmit={handleSubmitFollowUp}
        isSuccess={isFollowUpSuccess}
      />
      {/* <UpdateModal
        isOpen={isUpdateModal}
        onClose={() => setIsUpdateModal(false)}
        report={report}
      /> */}
    </div>
  );
};

export default ReportCard;
