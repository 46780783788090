import React from "react";
import { NavLink } from "react-router-dom";
import { RxPerson, RxExit } from "react-icons/rx";
import { MdDashboard } from "react-icons/md";
import { CiCreditCard1 } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";
import { TbReport } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export interface UserInfo {
  id: number;
  customID: string;
  email: string;
  role: string;
}

interface NavigationProps {
  isSidebarOpen: boolean;
}

interface LinkItem {
  to: string;
  iconClosed: React.ReactNode;
  iconOpen: React.ReactNode;
  label: string;
}

const iconStyle = "text-lg w-8 h-8";
const closedIconStyle = "w-10 h-10 text-gray-700";

const Navigation: React.FC<NavigationProps> = ({ isSidebarOpen }) => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const userEmail = userInfo?.customID || "";

  const getNavLinks = (email: string): LinkItem[] => [
    {
      to: `/manager/${email}/dashboard`,
      iconClosed: <MdDashboard className={closedIconStyle} />,
      iconOpen: <MdDashboard className={iconStyle} />,
      label: "Dashboard",
    },
    {
      to: `/manager/${email}/product`,
      iconClosed: <CiCreditCard1 className={closedIconStyle} />,
      iconOpen: <CiCreditCard1 className={iconStyle} />,
      label: "Product",
    },

    {
      to: `/manager/${email}/salesrep`,
      iconClosed: <FaUsers className={closedIconStyle} />,
      iconOpen: <FaUsers className={iconStyle} />,
      label: "SalesRep",
    },
    // {
    //   to: `/manager/${email}/report`,
    //   iconClosed: <TbReport className={closedIconStyle} />,
    //   iconOpen: <TbReport className={iconStyle} />,
    //   label: "Report",
    // },
  ];

  const bottomLinks: LinkItem[] = [
    {
      to: `/manager/${userEmail}/profile`,
      iconClosed: <RxPerson className={closedIconStyle} />,
      iconOpen: <RxPerson className={iconStyle} />,
      label: "Profile",
    },
    {
      to: "/",
      iconClosed: <RxExit className={closedIconStyle} />,
      iconOpen: <RxExit className={iconStyle} />,
      label: "Logout",
    },
  ];

  const NavItem: React.FC<{ item: LinkItem }> = ({ item }) => (
    <li>
      <NavLink
        to={item.to}
        className={({ isActive }) =>
          `flex items-center p-2 rounded-lg transition-all duration-300 ${
            isActive
              ? "bg-blue-100 text-blue-600"
              : "text-gray-700 hover:bg-gray-100"
          }`
        }
      >
        <span className="min-w-[2.5rem]">
          {isSidebarOpen ? item.iconOpen : item.iconClosed}
        </span>
        {isSidebarOpen && <span className="ml-2">{item.label}</span>}
      </NavLink>
    </li>
  );

  return (
    <aside className="h-full">
      <div className="flex flex-col space-y-32 h-full py-10">
        <ul className="space-y-4">
          {getNavLinks(userEmail).map((item) => (
            <NavItem key={item.to} item={item} />
          ))}
        </ul>

        <ul className="space-y-4 mt-auto">
          {bottomLinks.map((item) => (
            <NavItem key={item.to} item={item} />
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default Navigation;
