import React, { useState, useEffect, FormEvent } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import FileInput from "./FileInput";

interface Content {
  id: number;
  name: string;
  description: string;
  purpose: string;
  companyID?: string;
  companyName?: string;
  file_path?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string;
  upload_date?: string;
}

interface ContentUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (formData: FormData) => void;
  isLoading: boolean;
  contentData: Content;
}

const ContentUpdateModal: React.FC<ContentUpdateModalProps> = ({
  isOpen,
  onClose,
  onUpload,
  isLoading,
  contentData,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");

  // Check if any form field has been updated
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (contentData) {
      setName(contentData.name || "");
      setDescription(contentData.description || "");
      setPurpose(contentData.purpose || "");
    }
  }, [contentData]);

  useEffect(() => {
    const hasFieldChanged =
      name !== contentData.name ||
      description !== contentData.description ||
      purpose !== contentData.purpose ||
      file !== null;
    setHasChanges(hasFieldChanged);
  }, [name, description, purpose, file, contentData]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!hasChanges) {
      toast.error("No changes detected. Please update at least one field.");
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("name", name);
    formData.append("description", description);
    formData.append("purpose", purpose);

    onUpload(formData);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 py-8 z-50 overflow-y-auto"
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <motion.div 
        className="bg-white p-6 rounded-lg w-full max-w-2xl relative my-auto mx-auto"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.2 }}
      >
        <h2 className="text-3xl font-bold mb-4 py-3">Update Content</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <FileInput
            onChange={setFile}
            currentFileName={contentData.file_name}
          />

          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full px-4 py-2 border rounded-md border-gray-900 shadow-sm"
            />
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
              className="mt-1 block w-full px-4 py-2 border rounded-md border-gray-900 shadow-sm"
            />
          </div>

          <div>
            <label
              htmlFor="purpose"
              className="block text-sm font-medium text-gray-700"
            >
              Purpose
            </label>
            <input
              id="purpose"
              type="text"
              value={purpose}
              onChange={(e) => setPurpose(e.target.value)}
              className="mt-1 block w-full px-4 py-2 border rounded-md border-gray-900 shadow-sm"
            />
          </div>

          <div className="flex justify-center space-x-2 py-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 w-full border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 w-full border rounded-md text-sm font-medium text-white ${
                hasChanges ? "bg-blue-900 hover:bg-blue-700" : "bg-gray-400"
              }`}
              disabled={!hasChanges || isLoading}
            >
              {isLoading ? (
                <FaSpinner className="animate-spin mr-2" />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default ContentUpdateModal;