import React, { FC } from "react";
import Login from "../components/Login";
import Header from "../header/Header";

const LoginPage: FC = () => {
  return (
    <section className="relative">
      <Header /> <Login />
    </section>
  );
};

export default LoginPage;
